
h1 { 
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 700;
    color: #0077cc;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
}


form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    text-align: left;
  }
  
  input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #050505;
    margin-top: 5px;
  }
  
  button {
    background-color: #37a4f1;
    color: rgb(10, 10, 10);
    padding: 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-top: 10px;
  }
  
  button:hover {
    background-color: #005fa3;
  }